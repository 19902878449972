export const home= [
    //中文
    {
        //新闻模块3个卡片信息
        Newstit:'公司新闻',
        Newsbtn:'查看所有新闻',
        readmore:'阅读更多',
        allNews:[
            {
                //新闻标题
                Newstit:"重磅！中聚科技“泌尿外科智能精准导航高功率铥激光手术系统项目”入选湖南省高新技术产业科技创新引领计划",
                //新闻作者
                NewAuthor:"微引科技 微引科技 9月9日",
                //新闻摘要
                NewsSummary: "中聚科技股份有限公司,联合中南大学湘雅二医院和杭州微引科技有限公司共同实施的“泌尿外科智能精准导航高功率铥激光手术系统”入选湖南省高新技术产业科技创新引领计划（2021-2022年）并予以立项。",
                //跳转地址
                JumpAddress: 'https://mp.weixin.qq.com/s?__biz=MzkxMzE2NjYyNg==&mid=2247483674&idx=1&sn=f1c480d5cdee19af37b360b30b7b1998&chksm=c1009957f67710417c92f584fa56dcf5239302c4e9a0dd5b5df8b41b957e548b4507c85a3c47&token=905554539&lang=zh_CN#rd'
            },
            {
                //新闻标题
                Newstit:"手术机器人公司高容科技完成数千万元人民币Pre-A轮融资",
                //新闻作者
                NewAuthor:"王婵 2021年09月16",
                //新闻摘要
                NewsSummary: "9月16日，肿瘤微创介入手术机器人公司高容科技宣布完成数千万元人民币的Pre-A轮融资，由小苗朗程领投，邦明资本等跟投，老股东海脉德创投持续加码，势能资本担任独家财务顾问。",
                //跳转地址
                JumpAddress:'https://vcbeat.top/52444'
            },
            {
                //新闻标题
                Newstit:"动态 | 2021年“海外人才上海自贸区云+创业汇”活动开幕，高容科技获海外项目新锐榜TOP10及海外项目生物医药领域TOP20",
                //新闻作者
                NewAuthor:"高容科技 高容上海 2021年 11月16日",
                //新闻摘要
                NewsSummary: "10月25日，由上海市科协，上海市浦东新区区委组织部、浦东新区科经委、自贸区管委会张江管理局共同主办的2021年“海外人才上海自贸区云+创业汇”主体活动正式拉开帷幕。上海市科协党组成员陈丽，上海市浦东新区区委常委、组织部部长彭琼林，浦东新区科经委主任徐欣等出席活动。",
                //跳转地址
                JumpAddress:'https://mp.weixin.qq.com/s/X5kH4J-oIawfna-u9Wa_Yg'
            }
        ],
        //公司团队信息
        team:{
            companyTeamtit: '公司介绍',
            companyTeamDate: `高容科技以医疗影像引导的机器人平台为基础，为医院提供术前规划、术中导航、术后评估等相关的医疗智能化设备、耗材以及数字化服务，为医院提供微创以及介入手术解决方案`,
        }

    },


    //英文
    {
        //新闻模块3个卡片信息
        Newstit:'company news',
        Newsbtn:'View all news',
        readmore:'read more',
        allNews:[
            {   //新闻左1卡片
                //新闻标题
                Newstit:`Heavy! Sinopoly Technology's "Urinary Surgery Intelligent and Precise Navigation High-power Thulium Laser Surgery System Project" was selected into the Hunan High-tech Industry Science and Technology Innovation Leading Plan`,
                //新闻作者
                NewAuthor:"Weiyin Technology Weiyin Technology September 9",
                //新闻摘要
                NewsSummary: `China Poly Technology Co., Ltd., the Second Xiangya Hospital of Central South University and Hangzhou Weiyin Technology Co., Ltd. jointly implemented the "Urinary Surgery Intelligent and Precise Navigation High-power Thulium Laser Surgery System" selected into the Hunan High-tech Industry Science and Technology Innovation Leading Plan (2021- 2022) and the project will be approved.`,
                //跳转地址
                JumpAddress: 'https://mp.weixin.qq.com/s?__biz=MzkxMzE2NjYyNg==&mid=2247483674&idx=1&sn=f1c480d5cdee19af37b360b30b7b1998&chksm=c1009957f67710417c92f584fa56dcf5239302c4e9a0dd5b5df8b41b957e548b4507c85a3c47&token=905554539&lang=zh_CN#rd'
            },
            {   //新闻中间卡片
                //新闻标题
                Newstit:"Surgical robot company Gaorong Technology completes tens of millions of yuan in Pre-A round of financing",
                //新闻作者
                NewAuthor:"Wang Chan September 16, 2021",
                //新闻摘要
                NewsSummary: "On September 16, Gaorong Technology, a minimally invasive interventional surgical robot company for tumors, announced the completion of a Pre-A round of financing of tens of millions of yuan. Xiaomiao Langcheng led the investment, and Bangming Capital and other co-investors. The old shareholder Haimaide Ventures continued Overweight, Potential Capital serves as the exclusive financial advisor.",
                //跳转地址
                JumpAddress:'https://vcbeat.top/52444'
            },
            {   //新闻右1卡片
                //新闻标题
                Newstit:`News | In 2021, the "Overseas Talents Shanghai Free Trade Zone Cloud + Entrepreneurship Exchange" event will open, and Gaorong Technology won the TOP10 Overseas Projects Emerging List and the TOP20 Overseas Projects in the Biomedical Field`,
                //新闻作者
                NewAuthor:"Gaorong Technology Gaorong Shanghai November 16, 2021",
                //新闻摘要
                NewsSummary: `On October 25th, the 2021 "Overseas Talents Shanghai Free Trade Zone Cloud + Entrepreneurship Hui "The main event officially kicked off. Chen Li, member of the Party Group of the Shanghai Association for Science and Technology, Peng Qionglin, member of the Standing Committee of the Shanghai Pudong District Committee and Minister of the Organization Department, and Xu Xin, director of the Pudong Science and Economic Committee, attended the event.`,
                //跳转地址
                JumpAddress:'https://mp.weixin.qq.com/s/X5kH4J-oIawfna-u9Wa_Yg'
            }
        ],
        //公司介绍信息
        team:{
            companyTeamtit: 'Company introduction',
            companyTeamDate: `Based on a robotic platform guided by medical images, Gaorong Technology provides hospitals with preoperative planning, intraoperative navigation, postoperative evaluation and other related medical intelligent equipment, consumables and digital services, and provides hospitals with minimally invasive and interventional surgery solutions.`,
        }
    }
]
