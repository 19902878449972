export const profile= [
    //中文
    {
        // 关于我们标题
        profiletit:'关于我们',
        profilehui:'About Us',

        //公司简介模块
        allIntroduction:{
            Introductiontit:'成立.稳固.发展',
            IntroductionNR:`
                高容科技成立于2019年8月，团队来自美国GE、Mayo Clinic (梅奥医院)、卫宁医疗、美国赛生等业内领先的公司。公司以全球领先的人工智能技术为核心，提供以肺部为中心的术前规划术后评估，穿刺术中导航等智能化服务。
                公司拥有独立知识产权的AI数据分析平台（数据中台），该平台基于美国顶级医院和医疗技术公司的技术及产品经验，结合基于中国医疗机构的实际需求打造。基于该数据中台，公司为医疗机构进一步提供相关的手术评估和手术导航等智能服务。
            `
        },
        //研发中心模块
        allRandD:{
            RandDtit:'全球分部及研发中心',
            RandDNR:[
                '顶级学术、研究以及梅奥专家团队,公司机构技术支持.',
                '研发中心分布: 中国上海,中国杭州,美国,新加坡'
            ]
        },
        
        //产品矩阵模块
        allProductMatrix:{
            ProductMatrixtit:'产品矩阵',
            ProductMatrixNR:[
                '1. 数字医疗（独立自主知识产权）',
                '2. 医疗手术机器人平台 （独立自主知识产权）',
                '3. 手术耗材',
            ]
        },

        //服务医院模块
        ServiceHospitaltit: '国内服务医院',
    },

    //英文
    {
        // 关于我们标题
        profiletit:'About Us',
        profilehui:'关于我们',

        //公司简介模块
        allIntroduction:{
            Introductiontit:'Established. Stable. Development',
            IntroductionNR:`
            Gaorong Technology was established in August 2019. The team comes from leading companies in the industry such as GE, Mayo Clinic (Mayo Hospital), Weining Medical, and Cysen. With the world's leading artificial intelligence technology as the core, the company provides intelligent services such as lung-centered pre-operative planning and post-operative evaluation, and intraoperative navigation during puncture.
            The company has an AI data analysis platform (data center) with independent intellectual property rights, which is based on the technology and product experience of top US hospitals and medical technology companies, combined with the actual needs of Chinese medical institutions. Based on this data center, the company further provides related intelligent services such as surgical evaluation and surgical navigation for medical institutions.
            `
        },
        //研发中心模块
        allRandD:{
            RandDtit:'Global branches and R&D centers',
            RandDNR:[
                'Top academic, research and Mayo expert team, company technical support.',
                'Distribution of R&D centers: Shanghai, China, Hangzhou, China, U.S., Singapore'
            ]
        },
        
        //产品矩阵模块
        allProductMatrix:{
            ProductMatrixtit:'Product Matrix',
            ProductMatrixNR:[
                '1. Digital healthcare (independent intellectual property rights)',
                '2. Medical surgical robot platform (independent intellectual property rights)',
                '3. Surgical consumables',
            ]
        },

        //服务医院模块
        ServiceHospitaltit: 'Domestic Service Hospital',
    }
] 