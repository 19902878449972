export const introduction= [
    {
        //横幅标题
        PicName:'公司简介',
        //横幅灰字
        Pichui:'Company Profile',
        //公司简介所有内容
        allintroduction:{
            //内容标题 公司简介 |
            introductiontit: '政府支持',
            //简介内容1
            introductionNR1:'高容科技（上海）有限公司于2019年8月成立，是中国领先的手术影像数字服务提供商。公 司坐落于上海紫竹国家级高新区内，为政府扶持的内资高科技公司。公司创始团队来自美国 梅奥诊所、复旦大学、上海交通大学、美国通用电气等业内领先的医疗，科研机构及公司。',
            // 政府支持
            introductionNR2:[
                '上海市科技型中小企业',
                '上海市创新创业项目',
                '上海市浦江人才项目',
                '2021年中国上海自贸区云+创业汇新锐榜TOP10',
                '2021年中国上海自贸区云+创业汇生物医药TOP20',
                '2020年浙江省杭州市5213计划卓越类项目',
            ]
        }

    },



    {
        //横幅标题
        PicName:'Company Profile',
        //横幅灰字
        Pichui:'公司简介',
        //公司简介所有内容
        allintroduction:{
            //内容标题 Company Profile |
            introductiontit: 'Government Support',
            //简介内容1
            introductionNR1:`
            Gaorong Technology (Shanghai) Co., Ltd. was established in August 2019 and is a leading digital service provider for surgical imaging in China. The company is located in Shanghai Zizhu National High-tech Zone and is a domestic high-tech company supported by the government. The founding team of the company comes from leading medical and scientific research institutions and companies in the industry, such as Mayo Clinic, Fudan University, Shanghai Jiaotong University, and General Electric.
            `,
            // 政府支持
            introductionNR2:[
                'Shanghai Technology-based SMEs',
                'Shanghai Innovation and Entrepreneurship Project',
                'Shanghai Pujiang Talent Project',
                '2021 China Shanghai Free Trade Zone Cloud + Entrepreneurship Exchange TOP 10',
                '2021 China Shanghai Free Trade Zone Cloud + Chuangyehui Biomedical Top 20',
                '2020 Excellent Projects in the 5213 Plan of Hangzhou City, Zhejiang Province',
            ]
        }
    }
]
