export const joinContent= [
    {
        //横幅标题
        PicName:'加入我们',
        //横幅灰字
        Pichui:'Join Us',
        //岗位职责
        jobResponsibilities:'岗位职责',
        //任职要求
        jobRequirements:'任职要求',
        //招聘卡片数组信息
        allApply:[
            //高级图像算法工程师
            {   
                // 招聘职位名称
                ApplyName:'高级图像算法工程师',
                chip:['硕士','视觉图像算法','ITK','医疗图像','C++','杭州'],
                //岗位职责
                jobResponsibilities:[
                    '1. 负责医疗手术机器人控制系统软件总体需求设计、框架设计',
                    '2. 负责医疗手术机器人系统软件代码详细设计、编码和单元测试，以及相应设计文档的编写和维护。',
                    '3. 参与软硬件联调、系统联调与集成测试',
                    '4. 负责解决产品软件BUG，持续优化与提升产品性能、稳定性与可靠性',
                    '5. 根据公司研发流程，编写相应的软件开发文档。'
                ],
                jobRequirements:[
                    '1. 生物医学工程/电子工程/通讯/机械/计算机/自动控制等相关专业，本科及以上学历，具有本科3年以上或者硕士2年以上工作经验。',
                    '2. 熟练掌握C++。此外，熟悉其他Java/C/Python/matlab等语言者优先考虑。',
                    '3. 熟练掌握Windows/Linux/QNX/VxWorks等一种或者多种操作系统平台下的多线程、多进程编程。',
                    '4. 有各类工业现场总线如PCIe/CAN Open/EtherCAT等优先。',
                    '5. 有机器人运动学算法、闭环控制、图像处理，机器视觉算法开发经验优先。',
                    '6. 有移动机器人应用开发，如SLAM/定位/导航/避障/识别/控制与规划等开发经验优先。',
                    '7. 具有良好的团队协作能力和沟通能力，有较强的创新意识，可独立完成任务。',
                    '8. 具有较强的逻辑思维能力，扎实的编码功底，良好的代码风格。',
                    '9. 具有智能设备/机械传动设备/机器人本体/机械手臂/医疗机器人背景、医疗系统开发经验优先。深度学习开发相关经验者优先。'
                ],
                expanded:false,
            },
            //Java开发工程师
            {   
                // 招聘职位名称
                ApplyName:'Java开发工程师',
                chip:['本科','SQL','架构师','Spring','WEB开发','上海'],
                //岗位职责
                jobResponsibilities:[
                    '1.负责后台系统设计的迭代、开发、重构',
                    '2.编写代码实现产品功能，完成项目开发，撰写相关文档，参与需求分析',
                    '3.负责核心技术问题的攻关，架构设计、系统优化，协助解决项目开发过程中的技术难题',
                    '4.了解互联网的技术发展、评估外部技术与解决方案。',
                    '5.研发经理发布的临时任务。'
                ],
                jobRequirements:[
                    '1. 两年以上Java及Java Web开发经验， 具有扎实的程序设计基本功，有志于从事技术工作，能够独立担当功能模块的设计开发',
                    '2. 理解Java常用设计模式，并且能够合理运用，基本技术特性，如Java数据结构，多线程编程，Java IO等',
                    '3. 熟练使用Java常用框架和工具，如 Spring boot 、Spring Cloud、Hibernate、Mybatis等',
                    '4. 熟练使用mysql数据库以及sql的调优',
                    '5.熟练使用redis',
                    '6.有互联网行业开发经验者优先。',
                ],
                expanded:false,
            },

            //C++软件工程师
            {   
                // 招聘职位名称
                ApplyName:'C++软件工程师',
                chip:['VC++','图形图像处理','Linux','QT','硕士','杭州'],
                //岗位职责
                jobResponsibilities:[
                    '1.主要工作为开发医疗影像处理软件。',
                ],
                jobRequirements:[
                    '1. 熟练掌握C++，有Qt开发经验的更好',
                    '2. 熟悉或了解VTK，ITK等，有相关开发经验的优先',
                    '3. 软件工程，计算机，或生物医学工程等相关工程背景，有医疗相关工作经验优先。'
                ],
                expanded:false,
            },

            //硬件测试工程师
            {   
                // 招聘职位名称
                ApplyName:'硬件测试工程师',
                chip:['本科','PCB','BMC测试','可靠性测试','示波器','整机测试','主板测试','杭州'],
                //岗位职责
                jobResponsibilities:[
                    '1、 负责产品开发全流程过程中的系统功能、性能以及整机可靠性测试工作，负责测试需求分析，测试计划、方案和策略的制定、执行，挖掘产品存在的设计缺陷，并进行缺陷定位及改进跟踪验证，输出相关测试分析报告。',
                    '2、 负责测试过程所需设备的调研、选型、测试工装的需求以及测试平台的搭建。',
                    '3、 负责产品技术文档的编写。',
                    '4、 参与产品认证工作和企业标准的制定。'
                ],
                jobRequirements:[
                    '1、 本科及以上学历，有数电模电基础。',
                    '2、 具有较强的学习能力，能够快速掌握仪器仪表的知识和测试方法。',
                    '3、 具备自我驱动的工作态度，工作严谨认真，具备较强的质量意识，有意识的发现产品缺陷并推动缺陷修正。',
                    '4、 具有较强的动手能力，能够执行各项产品的系统测试工作。',
                    '5、 熟悉医疗器械相关法规，GB9706.1和YY0505、YY0709等。'
                ],
                expanded:false,
            },
            
            //医疗器械注册工程师
            {   
                // 招聘职位名称
                ApplyName:'医疗器械注册工程师',
                chip:['医疗注册','二类证','三类证','医疗器械生产质量管理规范','本科'],
                //岗位职责
                jobResponsibilities:[
                    '1、 负责产品国内外市场准入，注册临床试验相关事务及创新医疗器械申报',
                    '2、 编写/汇总国内外市场准入相关资料',
                    '3、 医疗器械生产、经营企业相关证书的维护和更新',
                    '4、 协助医疗器械产品注册路径的策划及认证工作的推动，执行',
                    '5、 负责国内外医疗器械法律法规的贯彻执行，及时了解医疗器械法律法规动态，获取新发布和新修订的法律法规',
                    '6、 产品及项目日常法规符合性保证',
                    '7、上级交办的其他工作。'
                ],
                jobRequirements:[
                    '1、本科及以上学历，专业不限，1-3年相关工作经验',
                    '2、具备极强的风控意识，熟悉法律法规和政策法规',
                    '3、具备优秀的文案功底',
                    '4、严谨认真，逻辑思维强，具备优秀的沟通协调能力。',
                ],
                expanded:false,
            },
            //三维重建工程师
            // {   
            //     // 招聘职位名称
            //     ApplyName:'三维重建工程师',
            //     chip:['医学影像','mimics','杭州','大专'],
            //     //岗位职责
            //     jobResponsibilities:[
            //         '1、医学影像（CT或MRI）的阅片',
            //         '2、使用三维重建软件将CT/MRI生成的DICOM图像制作成3D模型',
            //         '3、 了解mimics、3Dslicer等软件',
            //         '4、 负责制定公司三维重建团队工作计划并协助完成',
            //         '5、负责公司医学影像的数据标注',
            //         '6、 医学影像三维重建报告的编写。',
            //     ],
            //     jobRequirements:[
            //         '1、大专以上学历，应届生亦可，医学影像或临床等相关专业毕业',
            //         '2、较强的断层解剖识图能力、能够在CT图上快速准确的分辨出人体各器官和血管等组织',
            //         '3、熟练使用常用的办公软件，具有良好的文件编辑能力',
            //         '4、有医学影像组织器官和血管的三维重建经验者优先。',
            //     ],
            //     expanded:false,
            // },
            //医疗产品经理
            {   
                // 招聘职位名称
                ApplyName:'医疗产品经理',
                chip:['临床医学','医学影像学及相关专业','本科及以上学历'],
                //岗位职责
                jobResponsibilities:[
                    '1、参与公司医学团队及医学事务管理工作，包括但不限于研究计划及研究方案的制定，医学文件的撰写，临床方案的答疑和培训，临床研究过程中医学问题的收集和解答等相关工作',
                    '2、负责临床试验项目运营管理，监督、评估、指导临床研究项目整体进度和质量；对项目进行安全信息的评估、确保对安全报告的监管合规性；分析总结临床研究结果，撰写总结报告等医学文件',
                    '3、负责撰写、研究者手册中的医学部分，对研究者、专家、客户及团队内部提供产品知识培训；定稿研究者手册，并进行产品培训',
                    '4、与临床专家及研究者、临床试验机构、合作医院及其他合作商建立良好的合作关系并进行专业性沟通',
                    '5、追踪分析医疗手术器械研发领域的国内外医学动态及科研动态，了解领域进展及趋势',
                    '6、积极为其他部门/团队提供专业的学术支持，如市场、销售、研发等',
                    '7、积极完成上级交付的其他工作任务',
                ],
                jobRequirements:[
                    '1、临床医学、医学影像学及相关专业，本科及以上学历',
                    '2、两年及以上医疗器械临床研究/医学事务经验，有医疗科研平台、医疗器械行业经验优先；',
                    '3、有从0-1的项目全流程参与经验者优先',
                    '4、熟悉国内外医疗手术器械临床试验相关的法律、法规，设计、流程及管理',
                    '5、熟练使用国内外医学文献数据库及搜索引擎',
                    '6、具有良好的英文听说读写能力者优先',
                    '7、逻辑清晰，谈吐得体，优秀的口头表达能力、文件撰写能力、计划管理能力和督导能力',
                ],
                expanded:false,
            }
        ],
        //福利
        welfare:{
            welfareName:'员工福利',
            welfareDate:[
                '五天制，双休',
                '弹性上下班制度，每天工作8小时，午休1小时，法定节假日均正常休息。',
                '员工转正有带薪年假、带薪病假。',
                '中国传统节日发放员工福利物资或购物卡。',
                '司庆日、员工生日的团建活动、发放生日福利。',
                '不定期举办丰富文体娱乐员工活动、干货分享交流活动。',
                '无加班文化，休息日工作正常结算薪资。',
                '每年定期组织员工年度体检，关注每位员工身心健康。',
                '员工内推、专利申请均有相应奖励。',
                '年轻、富有活力的团队，人性化管理制度。',
                '...........'
            ]
        },
        //招聘职位
        recruitName:'招聘职位',
        RecruitmentPosition:`高级图像算法工程师、Java开发工程师、C++软件工程师、硬件测试工程师、医疗器械注册工程师、三维重建工程师。`,
        Workcity:{
            WorkName:'工作地点',
            cityName:'杭州,上海'
        },
        cllme: '联系我们'

    },



    {
        //横幅标题
        PicName:'Join Us',
        //横幅灰字
        Pichui:'加入我们',
        //岗位职责
        jobResponsibilities:'job Responsibilities',
        //任职要求
        jobRequirements:'job Requirements',
        //招聘卡片数组信息
        allApply:[
            //高级图像算法工程师
            {   
                // 招聘职位名称
                ApplyName:'Senior image algorithm engineer',
                chip:['master','Visual image algorithm','ITK','Medical image','C++','Hangzhou'],
                //岗位职责
                jobResponsibilities:[
                    '1. Responsible for the overall requirements design and framework design of the medical surgical robot control system software',
                    '2. Responsible for the detailed design, coding and unit testing of the medical surgical robot system software code, as well as the preparation and maintenance of the corresponding design documents.',
                    '3. Participate in software and hardware joint debugging, system joint debugging and integration testing',
                    '4. Responsible for solving product software bugs, continuously optimizing and improving product performance, stability and reliability',
                    "5. Compile corresponding software development documents according to the company's R&D process.",
                ],
                jobRequirements:[
                    "1. Biomedical engineering/electronic engineering/communication/mechanics/computer/automatic control and other related majors, bachelor degree or above, with more than 3 years of bachelor's degree or more than 2 years of master's degree of work experience.",
                    '2. Familiar with C++. In addition, those who are familiar with other languages such as Java/C/Python/matlab are preferred.',
                    '3. Familiar with multi-thread and multi-process programming under one or more operating system platforms such as Windows/Linux/QNX/VxWorks.',
                    '4. There are various industrial field buses such as PCIe/CAN Open/EtherCAT and so on.',
                    '5. Robot kinematics algorithm, closed-loop control, image processing, machine vision algorithm development experience is preferred.',
                    '6. Mobile robot application development, such as SLAM/positioning/navigation/obstacle avoidance/recognition/control and planning development experience is preferred.',
                    '7. Have good teamwork and communication skills, have a strong sense of innovation, and can complete tasks independently.',
                    '8. Have strong logical thinking ability, solid coding skills, and good coding style.',
                    '9. Background of intelligent equipment/mechanical transmission equipment/robot body/mechanical arm/medical robot, medical system development experience is preferred. Deep learning development related experience is preferred.'
                ],
                expanded:false,
            },
            //Java开发工程师
            {   
                // 招聘职位名称
                ApplyName:'Java Development Engineer',
                chip:['Undergraduate','SQL','Architect','Spring','WEB development','Shanghai'],
                //岗位职责
                jobResponsibilities:[
                    '1. Responsible for the iteration, development and reconstruction of the back-end system design',
                    '2. Write code to implement product functions, complete project development, write related documents, and participate in demand analysis',
                    '3. Responsible for tackling key technical problems, architecture design, system optimization, and assisting in solving technical problems in the project development process',
                    '4. Understand the technological development of the Internet and evaluate external technologies and solutions.',
                    '5. Temporary tasks issued by the R&D manager.'
                ],
                jobRequirements:[
                    '1. More than two years of experience in Java and Java Web development, with solid basic skills in programming, aspiring to engage in technical work, and able to independently design and develop functional modules',
                    '2. Understand the common design patterns of Java, and be able to use them reasonably, basic technical features, such as Java data structure, multi-threaded programming, Java IO, etc.',
                    '3. Proficiency in using common Java frameworks and tools, such as Spring boot, Spring Cloud, Hibernate, Mybatis, etc.',
                    '4. Familiar with mysql database and sql tuning',
                    '5. Use redis proficiently',
                    '6. Experience in Internet industry development is preferred.',
                ],
                expanded:false,
            },

            //C++软件工程师
            {   
                // 招聘职位名称
                ApplyName:'C++ software engineer',
                chip:['VC++','Graphics and image processing','Linux','QT','master','Hangzhou'],
                //岗位职责
                jobResponsibilities:[
                    '1.The main work is to develop medical image processing software.',
                ],
                jobRequirements:[
                    '1. Familiar with C++, Qt development experience is better',
                    '2. Familiar with or understand VTK, ITK, etc., relevant development experience is preferred',
                    '3. Software engineering, computer, or biomedical engineering and other related engineering background, medical related work experience is preferred.'
                ],
                expanded:false,
            },

            //硬件测试工程师
            {   
                // 招聘职位名称
                ApplyName:'Hardware test engineer',
                chip:['Undergraduate','PCB','BMC test','Reliability test','Oscilloscope','Whole machine test','Motherboard test','Hangzhou'],
                //岗位职责
                jobResponsibilities:[
                    '1、 Responsible for the system function, performance and reliability testing of the whole product development process, responsible for the analysis of test requirements, the formulation and execution of test plans, schemes and strategies, mining of product design defects, and defect location and improvement tracking Verify and output related test analysis reports.',
                    '2、 Responsible for the investigation and selection of equipment required for the test process, the requirements of test tooling, and the construction of test platform.',
                    '3、 Responsible for the preparation of product technical documentation.',
                    '4、 Participate in product certification and the formulation of corporate standards.'
                ],
                jobRequirements:[
                    '1、 Bachelor degree or above, with a foundation in digital, electrical and analog electronics.',
                    '2、 Have strong learning ability and be able to quickly master the knowledge and test methods of instrumentation.',
                    '3、 Have a self-driven work attitude, work rigorously and seriously, have a strong sense of quality, consciously find product defects and promote defect correction.',
                    '4、 Have strong hands-on ability and be able to perform system testing of various products.',
                    '5、 Familiar with relevant regulations of medical devices, such as GB9706.1, YY0505, YY0709, etc.'
                ],
                expanded:false,
            },
            
            //医疗器械注册工程师
            {   
                // 招聘职位名称  
                ApplyName:'Medical Device Registered Engineer',
                chip:['Medical registration','Type 2 certificate','Three types of certificates','Medical device production quality management norms','Undergraduate'],
                //岗位职责
                jobResponsibilities:[
                    '1、 Responsible for product domestic and foreign market access, registration of clinical trial related affairs and innovative medical device declaration',
                    '2、 Compile/aggregate relevant information on domestic and foreign market access',
                    '3、 Maintenance and renewal of relevant certificates for medical device manufacturing and operating companies',
                    '4、 Assist in the planning of medical device product registration path and promotion and implementation of certification',
                    '5、 Responsible for the implementation of domestic and foreign medical device laws and regulations, keep abreast of medical device laws and regulations, and obtain newly released and revised laws and regulations',
                    '6、 Guarantee of compliance with daily regulations of products and projects',
                    '7、Other tasks assigned by superiors.'
                ],
                jobRequirements:[
                    '1、Bachelor degree or above, no major limitation, 1-3 years of relevant work experience',
                    '2、Have a strong sense of risk control, familiar with laws and regulations, policies and regulations',
                    '3、Have excellent copywriting skills',
                    '4、Rigorous and serious, strong logical thinking, and excellent communication and coordination skills.',
                ],
                expanded:false,
            },
            //三维重建工程师
            // {   
            //     // 招聘职位名称
            //     ApplyName:'3D reconstruction engineer',
            //     chip:['Medical Imaging','mimics','Hangzhou','Junior college'],
            //     //岗位职责
            //     jobResponsibilities:[
            //         '1、Reading of medical images (CT or MRI)',
            //         '2、Use the 3D reconstruction software to make the DICOM image generated by CT/MRI into a 3D model',
            //         '3、 Understand mimics, 3Dslicer and other software',
            //         "4、 Responsible for formulating the company's 3D reconstruction team work plan and assisting in completion",
            //         "5、Responsible for the data annotation of the company's medical images",
            //         '6、 Compilation of 3D reconstruction reports for medical images.',
            //     ],
            //     jobRequirements:[
            //         '1、College degree or above, fresh graduates can also be graduated from related majors such as medical imaging or clinical',
            //         '2、Strong tomographic and anatomical image recognition ability, able to quickly and accurately distinguish various organs and blood vessels and other tissues of the human body on CT images',
            //         '3、Familiar with commonly used office software, with good file editing ability',
            //         '4、Experience in 3D reconstruction of medical imaging tissues, organs and blood vessels is preferred.',
            //     ],
            //     expanded:false,
            // },
            //医疗产品经理
            {   
                // 招聘职位名称
                ApplyName:'Medical Product Manager',
                chip:['clinical medicine','Medical Imaging and related majors','Bachelor degree or above'],
                //岗位职责
                jobResponsibilities:[
                    `1、Participate in the company's medical team and medical affairs management, including but not limited to the formulation of research plans and research protocols, the writing of medical documents, the answering and training of clinical protocols, and the collection and answering of medical questions during clinical research.`,
                    '2、Responsible for the operation and management of clinical trial projects, supervise, evaluate, and guide the overall progress and quality of clinical research projects; evaluate project safety information and ensure regulatory compliance of safety reports; analyze and summarize clinical research results, and write summary reports and other medical documents',
                    `3、Responsible for writing the medical part of the researcher's manual, providing product knowledge training to researchers, experts, customers and the team; finalizing the researcher's manual, and conducting product training`,
                    '4、Establish good cooperative relations and conduct professional communication with clinical experts and researchers, clinical trial institutions, cooperative hospitals and other partners',
                    '5、Track and analyze the domestic and foreign medical trends and scientific research trends in the field of medical and surgical instrument research and development, and understand the progress and trends in the field',
                    '6、Actively provide professional academic support for other departments/teams, such as marketing, sales, R&D, etc.',
                    '7、Actively complete other tasks assigned by superiors',
                ],
                jobRequirements:[
                    '1、Clinical medicine, medical imaging and related majors, bachelor degree or above',
                    '2、Two or more years of medical device clinical research/medical affairs experience, experience in medical research platform and medical device industry is preferred',
                    '3、Experience in participating in the whole process of the project from 0-1 is preferred',
                    '4、Familiar with domestic and foreign laws, regulations, design, process and management related to clinical trials of medical surgical devices',
                    '5、Proficient in using domestic and foreign medical literature databases and search engines',
                    '6、Good English listening, speaking, reading and writing skills are preferred',
                    '7、Clear logic, decent speech, excellent oral expression ability, document writing ability, plan management ability and supervision ability',
                ],
                expanded:false,
            }
        ],
        //福利
        welfare:{
            welfareName:'Employee Benefits',
            welfareDate:[
                'Five-day system, weekends',
                'Flexible commuting system, working 8 hours a day, 1 hour lunch break, and normal rest on statutory holidays.',
                'Employees who become regular employees have paid annual leave and paid sick leave.',
                'Distribute employee welfare materials or shopping cards on traditional Chinese holidays.',
                'Team building activities on the company’s day and employee’s birthday, and birthday benefits.',
                'Organize rich cultural, sports and entertainment employee activities and dry goods sharing and exchange activities from time to time.',
                'There is no overtime culture, and the salary is settled normally when working on rest days.',
                'The annual physical examination of employees is regularly organized every year to pay attention to the physical and mental health of each employee.',
                'Staff referrals and patent applications are rewarded accordingly.',
                'Young and energetic team, humanized management system.',
                '...........'
            ]
        },
        //招聘职位
        recruitName:'Recruitment',
        RecruitmentPosition:`Senior image algorithm engineer, Java development engineer, C++ software engineer, hardware test engineer, medical device registered engineer, 3D reconstruction engineer.`,
        Workcity:{
            WorkName:'work place',
            cityName:'Hangzhou, Shanghai'
        },
        // 联系我们
        cllme: 'contact Us'

    }
]

