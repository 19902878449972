export const contact= [
    //中文
    {
        // 联系我们标题
        connecttit:'联系我们',
        connecthui:'contact us',
        //公司总部标题
        HeadquartersTit:'公司总部',
        //上海地址
        SHheadquartersAddress:[
            "地址: 上海市闵行区东川路555号紫竹数码港6号楼",
            "客服邮箱: cs@hcit.ai",
            "简历投递邮箱：weiyin@hcit.ai",
            "电话：021-61200998",
        ],
        //杭州地址
        HZheadquartersAddress:[
            "地址: 浙江省杭州市萧山区启迪路198号杭州湾信息港F座",
            "客服邮箱: cs@hcit.ai",
            "简历投递邮箱：weiyin@hcit.ai",
            "电话：021-61200998"
        ]

    },


    //英文
    {
        // 联系我们标题
        connecttit:'Contact Us',
        connecthui:'联系我们',
        //公司总部标题
        HeadquartersTit:'Company headquarters',
        //上海地址
        SHheadquartersAddress:[
            "Address: Building 6, Zizhu Cyberport, 555 Dongchuan Road, Minhang District, Shanghai",
            "Customer Service Email: cs@hcit.ai",
            "Resume delivery email: weiyin@hcit.ai",
            "Phone: 021-61200998"
        ],
        HZheadquartersAddress:[
            "Building F, Hangzhou Bay Information Port, 198 Qidi Road, Xiaoshan District, Hangzhou City, Zhejiang Province",
            "Customer Service Email: cs@hcit.ai",
            "Resume delivery email: weiyin@hcit.ai",
            "Phone: 021-61200998"
        ]
    }
]
