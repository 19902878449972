<template>
  <width-switch :width='600'> 

    
    <!-- 宽度大于500时 -->
    <div id="app" slot="a" ref="kgl">
       <!-- 顶部区域 -->
        <div class="guding">
          <div class='top'>
            <div class="biaoti">
              {{$t('appcontent.top.topbiaoti')}}
            </div>
            <div class="function">
              <a href="#home" @click="goHome">{{$t('appcontent.top.function.functionbtn1')}}</a>
              <!-- <a href="#Introduction" @click="goIntroduction">{{$t('appcontent.top.function.functionbtn2')}}</a> -->
              <a href="#News" @click="goNews">{{$t('appcontent.top.function.functionbtn3')}}</a>
              <a href="#Profile" @click="goProfile">{{$t('appcontent.top.function.functionbtn4')}}</a>
              <a href="#join" @click="goJoin">{{$t('appcontent.top.function.functionbtn5')}}</a>
              <a href="#Contact" @click="goContact">{{$t('appcontent.top.function.functionbtn6')}}</a>
              <q-icon  v-if='language' name="iconfont icon-zhongyingqiehuan-qiehuanzhongwen" size="sm" @click="change('en')"/>
              <q-icon  v-else name="iconfont icon-zhongyingqiehuan-qiehuanyingwen" size="sm" @click="change('zh')"/>
            </div>
          </div>
        </div>
        <div style='width: 100%;'>
          <router-view></router-view>
          
        </div>
        <!-- 底部区域 -->
        <div class='foot'>
          <div class='footqian'>
            <div class="TelephonePIC">
              <!-- <img src="./assets/狗.jpg" alt=""> -->
              <div>·</div>
              <div class="logo">hict.ai</div>
            </div>
            <!-- 公司地址电话 -->
            <div class="Telephone">
              <div class='TelephoneNR' v-for="(item,i) in $t('appcontent.foot.Telephone.TelephoneNR')" :key='i'>
                <div class='fw24'>
                  <span class='tit'>{{item.NR1}}</span>
                  <span>{{item.NR2}}</span>
                  <span>{{item.NR3}}</span>
                  <span>{{item.NR4}}</span>
                  <span>{{item.NR5}}</span>
                </div>
                <div class='shouji'>
                  <span>{{item.shouji.shouji1}}</span>
                  <span>{{item.shouji.email}}</span>
                </div>
              </div>
              
              <!-- <div class='TelephoneNR'>
                <div class='fw24'>
                  <span class='tit'>上海高容科技</span>
                  <span>75 Sgt William B Terry Drive</span>
                  <span>Suite 2400</span>
                  <span>Hingham, MA 02043</span>
                  <span>China</span>
                </div>
                <div class='shouji'>
                  <span>电话： +1-888-317-XACT (9228)</span>
                  <span>电话： +1-888-317-XACT (9228)</span>
                </div>
              </div> -->
            </div>
            <!-- 模块跳转 -->
            <div class='footFunction'>
              <!-- 首页 -->
              <div>
                <a href="#home" @click="goHome">{{$t('appcontent.foot.footFunction[0]')}}</a>
              </div>
              <!-- 公司简介 -->
              <!-- <div>
                <a href="#Introduction" @click="goIntroduction">{{$t('appcontent.foot.footFunction[1]')}}</a>
              </div> -->
              <!-- 公司新闻 -->
              <div>
                <a href="#News" @click="goNews">{{$t('appcontent.foot.footFunction[2]')}}</a>
              </div>
              <!-- 关于我们 -->
              <div>
                <a href="#Profile" @click="goProfile">{{$t('appcontent.foot.footFunction[3]')}}</a>
              </div>
              <!-- 加入我们 -->
              <div>
                <a href="#join" @click="goJoin">{{$t('appcontent.foot.footFunction[4]')}}</a>
              </div>
              <!-- 联系我们 -->
              <div>
                <a href="#Contact" @click="goContact">{{$t('appcontent.foot.footFunction[5]')}}</a>
              </div>
            </div>
          </div>
          <div class='footshen'>
            {{$t('appcontent.foot.copyright')}}
          </div>
        </div>
        
    </div>



    <!-- 宽度小于500时 -->
    <div id="app" slot="b">
      <!-- 顶部区域 -->
      <div class='Phtop'>
        <div class='logo'>
          Hcit.ai
        </div>
        <div class='logobtn'>
          <q-btn flat round dense icon="menu" class="q-mr-sm" size='lg' >
            <q-menu :offset="[40, 0]" anchor="bottom middle" self="top middle">
              <div class="no-wrap q-pa-md personal">
                  <div class="column items-center">
                      <!-- <q-avatar style='margin:0.75rem 0;' size="30px">
                          <img src="../assets/avatar.png">
                      </q-avatar> -->
                      <!-- <q-list class="personalbtn" bordered separator>
                          <q-item clickable v-ripple dense>
                              <q-item-section>{{userName}}</q-item-section>
                          </q-item>
                      </q-list> -->
                      <q-list class="personalbtn"  separator dense>
                          <q-item clickable v-ripple>
                            <q-item-section @click="goHome">{{$t('appcontent.top.function.functionbtn1')}}</q-item-section>
                          </q-item>
                      </q-list>
                      <q-list class="personalbtn"  separator dense>
                          <q-item clickable v-ripple>
                            <q-item-section @click="goNews">{{$t('appcontent.top.function.functionbtn3')}}</q-item-section>
                          </q-item>
                      </q-list>
                      <q-list class="personalbtn"  separator dense>
                          <q-item clickable v-ripple>
                            <q-item-section @click="goProfile">{{$t('appcontent.top.function.functionbtn4')}}</q-item-section>
                          </q-item>
                      </q-list>
                      <q-list class="personalbtn"  separator dense>
                          <q-item clickable v-ripple>
                            <q-item-section @click="goJoin">{{$t('appcontent.top.function.functionbtn5')}}</q-item-section>
                          </q-item>
                      </q-list>
                      <q-list class="personalbtn"  separator dense>
                          <q-item clickable v-ripple>
                            <q-item-section @click="goContact">{{$t('appcontent.top.function.functionbtn6')}}</q-item-section>
                          </q-item>
                      </q-list>
                      <q-list class="personalbtn"  separator dense>
                          <q-item clickable v-ripple>
                            <q-item-section>
                              <q-icon  v-if='language' name="iconfont icon-zhongyingqiehuan-qiehuanzhongwen" size="sm" @click="change('en')"/>
                              <q-icon  v-else name="iconfont icon-zhongyingqiehuan-qiehuanyingwen" size="sm" @click="change('zh')"/>
                            </q-item-section>
                          </q-item>
                      </q-list>
                      
                  </div>
              </div>
            </q-menu>
          </q-btn>
        </div>
      </div>
      
      
      <div class='nr'>
          <router-view></router-view>
      </div>

      <!-- 底部区域 -->
      <div class='Phfoot'>
        <div class='Phfoottit'>HCIT.AI</div>
        <div class='PHTelephoneNR' v-for="(item,i) in $t('appcontent.foot.Telephone.TelephoneNR')" :key='i'>
          <div class='phfw24'>
            <span class='tit'>{{item.NR1}}</span>
            <span>{{item.NR2}}</span>
            <span>{{item.NR3}}</span>
            <span>{{item.NR4}}</span>
            <span>{{item.NR5}}</span>
          </div>
          <div class='phshouji'>
            <span>{{item.shouji.shouji1}}</span>
            <span>{{item.shouji.email}}</span>
          </div>
        </div>
        <div class="PHfootFunction">
          <!-- 首页 -->
          <div>
            <a href="#home" @click="goHome">{{$t('appcontent.foot.footFunction[0]')}}</a>
          </div>
          <!-- 公司简介 -->
          <!-- <div>
            <a href="#Introduction" @click="goIntroduction">{{$t('appcontent.foot.footFunction[1]')}}</a>
          </div> -->
          <!-- 公司新闻 -->
          <div>
            <a href="#News" @click="goNews">{{$t('appcontent.foot.footFunction[2]')}}</a>
          </div>
          <!-- 关于我们 -->
          <div>
            <a href="#Profile" @click="goProfile">{{$t('appcontent.foot.footFunction[3]')}}</a>
          </div>
          <!-- 加入我们 -->
          <div>
            <a href="#join" @click="goJoin">{{$t('appcontent.foot.footFunction[4]')}}</a>
          </div>
          <!-- 联系我们 -->
          <div>
            <a href="#Contact" @click="goContact">{{$t('appcontent.foot.footFunction[5]')}}</a>
          </div>
        </div>
        <div class='PHfootshen'>
            {{$t('appcontent.foot.copyright')}}
          </div>
      </div>

    </div>
  </width-switch>
    
</template>
<script>
import WidthSwitch from '@/components/Widthwatch/WidthSwitch.vue'

import './styles/allstyes.less'
import './styles/Phone/Pallstyles/Pallstyles.less'
export default {
  data() {
    return {
      language:true
    }
  },
  mounted(){
  },
  created(){
  },
  computed:{
  },
  components:{
    WidthSwitch
  },
  methods:{
    change(row){
      if(this.language){
        this.language=false
        console.log(this.$i18n.locale);
        this.$i18n.locale = row
      }else{
        this.language=true
        console.log(this.$i18n.locale);

        this.$i18n.locale = row
      }
    },
    fuwu(){
      console.log(1);
      console.log(this.$router);
    },
    // 加入我们
    goJoin(){
      if(this.$route.path == '/Join'){
        return
      }
      this.$router.push('/Join')
      this.$refs.kgl.animate({ scrollTop: 0 }, 500) //点击按钮让其回到页面顶部
    },
    //公司简介
    goIntroduction(){
      if(this.$route.path == '/Introduction'){
        return
      }
      this.$router.push('/Introduction')
      this.$refs.kgl.animate({ scrollTop: 0 }, 500) //点击按钮让其回到页面顶部
    },
    //公司新闻
    goNews(){
      if(this.$route.path == '/News'){
        return
      }
      this.$router.push('/News')
      document.body.animate({ scrollTop: 0 }, 500)
      // this.$refs.kgl.animate({ scrollTop: 0 }, 500) //点击按钮让其回到页面顶部
    },
    // 首页
    goHome(){
      if(this.$route.path == '/Home'){
        return
      }
      this.$router.push('/Home')
      // document.getElementsByTagName('body').style.scrollTop=0
      this.$refs.kgl.animate({ scrollTop: 0 }, 500) //点击按钮让其回到页面顶部
    },
    // 联系哦们
    goContact(){
      if(this.$route.path == '/Contact'){
        return
      }
      this.$router.push('/Contact')
      this.$refs.kgl.animate({ scrollTop: 0 }, 500) //点击按钮让其回到页面顶部  
    },
    // 关于我们
    goProfile(){
      if(this.$route.path == '/Profile'){
        return
      }
      this.$router.push('/Profile')
      this.$refs.kgl.animate({ scrollTop: 0 }, 500) //点击按钮让其回到页面顶部
    },
    
    clickTo() {
      log(11)
      document.getElementsByTagName('body')('.foot-content-brand-image').click(function () {
        document.getElementsByTagName('body').animate({ scrollTop: 0 }, 500) //点击按钮让其回到页面顶部
      })
    },
  },
}
</script>
<style lang="less" scoped>
.personal{
  padding: 0;
  width: 300px;
}

</style>
